body.kontakt {
  section iframe {
    filter: grayscale(100%);
  }
  main.container {
    font-size: 26px;
    line-height: 32px;

    div.row {
      padding: 0 20px;
    }
    p {
      margin-bottom: 3rem;
    }
    img {
      width: 200px;
      margin-bottom: 3rem;
    }
  }

  form {
    margin-bottom: 10rem;

    label {
      display: block;
    }
    input, textarea {
      width: 100%;
      margin-bottom: 10px;
    }
    button {
      color: white;
      background-color: $gray4;
    }
  }
}
#dynamic_form1_url, label[for="dynamic_form1_url"] {
  display: none;
}
