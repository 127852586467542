div.gallery {
  color: $gray4;
  background-color: $gray5;
  padding-top: 20px;
  padding-bottom: 60px;
  margin-bottom: 80px;

  p {
    margin-bottom: 2rem;
  }
  img {
    width: 325px;
    height: 257px;
    margin: 0 10px 20px 0;
    box-shadow: 3px 3px 10px $gray4;
  }
  span {
    display: inline-block;
    width: 300px;
    height: 50px;
    overflow: hidden;
    margin: 210px 0 0 10px;
    position: absolute;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 5px 5px 10px black;
  }
}
