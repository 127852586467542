body {
  font-size: 32px;
  padding-top: 55px;
  font-family: 'Roboto Condensed', sans-serif;
  color: $gray2;
}
main {
  min-height: 60vh;

  a {
    color: $gray2;
    font-weight: bold;
    text-decoration: none;
  }

  &.green-dots {
    ul {
      list-style: none;
      margin-block: 0;
      padding-inline-start: 0;

      li {
        line-height: 2.5rem;
      }
      li::before {
        content: "\25CF";
        color: $lightgreen;
        font-size: 34pt;
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 2rem; /* Also needed for space (tweak if needed) */
      }
    }
  }
}
main p, p, main li {
  line-height: 38px;
  letter-spacing: -1px;
}

img.img-fluid {
  width: 100%;
}
div.claim {
  padding-top: 8px;
}
a.navbar-toggler {
  float: right;
  padding-right: 55px;
  cursor: pointer;

  .navbar-toggler-icon {
    color: $gray3;
    letter-spacing: -10px;
    font-size: 26px;
    font-stretch: expanded;
  }
}

img.play-button {
  cursor: pointer;
}

@include media-breakpoint-down(md) {
  body {
    padding-top: 20px;
  }
  a.navbar-toggler {
    padding-right: 20px;
  }
}
@include media-breakpoint-down(sm) {
  body {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.no-text {
  font-size: 0;
}

footer {
  color: white;
  background-color: $gray2;
  min-height: 200px;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 22px;
  line-height: 26px;
  padding: 30px 0 50px 0;

  div.navTitle {
    text-transform: uppercase;
    padding-bottom: 30px;
  }

  a, a:focus, a:hover, a:visited {
    color: white;
    text-decoration: none;
  }
}

.mt-big {
  margin-top: 70px;
  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }
}
