main.engagement {
  div.tafel-divider {
    margin: 3rem 0;
  }
  div.soccer-divider {
    margin: -2rem 0 3rem;
  }
  div.unbezahlbar-divider {
    margin: 4rem 0 2rem;
  }

  div.videoBox {
    div.video {
      position: relative;
    }
    img.play-button {
      width: 110px;
      display: block;
      position: absolute;
      bottom: 80px;
      left: 188px;
    }
    span.videoBoxTitle {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      font-size: 55px;
      background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(119,119,119,0.5) 50%, rgba(255,255,255,0) 100%);
    }
    p::before {
      content: "";
      width: 38px;
      height: 38px;
      float: left;
      background-image: url('../images/rosa-pfeil.svg');
      background-size: 38px;
      margin-right: 0.7rem;
    }
  }

  #abschluss {
    height: 170px;
  }
}