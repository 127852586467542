img.youthLogo {
  margin: -260px auto 0 auto;
  width: 700px;
  display: block;
}
img.splash {
  float: right;
  width: 700px;
  margin-left: -700px;
}
main.vinyouth {
  p, ul {
    margin-bottom: 3rem;
  }
}
