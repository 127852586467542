main.kleingruppen {
  a {
    color: $gray2;
    text-decoration: none;
  }

  .twoColumns {
    column-count: 2;
    column-gap: 20px;
  }
  ul + p, li {
    margin-top: 2rem;
  }

  p.link {
    a {
      display: block;
      color: white;
      background-color: $gray2;
      margin: 50px 0;
      padding: 11px 20px;
    }

    img.pfeil {
      width: 60px;
      float: right;
      margin: -11px 30px 0 0;
    }
  }

  div.image {
    width: 900px;
    height: 415px;

    &.left {
      position: absolute;
      left: 0;
    }
    &.right {
      margin-left: 520px;
    }
  }
}