body.homepage {
    section img.hero-image {
        margin-top: 20px;
    }

    h1, .bigGreenTitle h3, div.videoBoxTitle {
        color: $lightgreen;
        font-size: 76px;
        font-family: 'DIN Condensed', sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: -10px;
    }
    h1, .bigGreenTitle h3 {
        padding-top: 10px;
        background-image: linear-gradient($gray2 0, $gray2 100%);
        background-size: 60px 7px;
        background-repeat: no-repeat;
    }
    h2, .bigGreenTitle div.rightBottomCorner {
        font-size: 10px;
        background-image: linear-gradient($gray2 0, $gray2 100%);
        background-size: 60px 7px;
        background-position: 100% 100%;
        background-repeat: no-repeat;
    }
    h2 {
        font-size: 50px;
        font-weight: bold;
        color: $gray4;
    }

    div.bigGreenTitle {
        margin: 20px;
        background-image: linear-gradient($gray2 0, $gray2 60px, white 60px), linear-gradient(white 0, white calc(100% - 60px), $gray2 calc(100% - 60px));
        background-size: 7px 100%;
        background-position: 0 0, 100% 0;
        background-repeat: no-repeat;

        h3 {
            text-align: center;
            padding: 10px;
        }
    }

    div.headlines {
        margin-top: 40px;
        margin-bottom: 35px;
        background-image: linear-gradient($gray2 0, $gray2 60px, white 60px), linear-gradient(white 0, white calc(100% - 60px), $gray2 calc(100% - 60px));
        background-size: 7px 100%;
        background-position: 0 0, 100% 0;
        background-repeat: no-repeat;
    }

    main p {
        font-size: 34px;
    }

    svg.weiter-pfeil {
        width: 128px;
        margin-bottom: 30px;
        * {
            fill: $gray2;
        }
    }

    div.videoBox {
        background-image: linear-gradient($gray2 0, $gray2 60px, white 60px), linear-gradient(white 0, white calc(100% - 60px), $gray2 calc(100% - 60px));
        background-size: 7px 100%;
        background-position: 0 0, 100% 0;
        background-repeat: no-repeat;
        padding: 0;

        & > div {
            background-image: linear-gradient($gray2 0, $gray2 100%);
            background-size: 60px 7px;
            background-repeat: no-repeat;
            padding-left: 27px;
        }

        & > div:first-child {
            padding-top: 27px;
        }

        & > div:last-child {
            padding: 20px 27px 12px 0;
            background-position: 100% 100%;
        }

        img.play-button {
            margin-top: -150px;
        }
    }

    div.videoBoxTitle {
        margin-bottom: 10px;
    }

    div.headline {
        font-size: 34px;
        font-weight: bold;
        color: $gray4;
    }

    div.videosBereiche {
        div.row:first-child {
            margin: 70px 0 35px 0;
        }

        div.bereichsvideo {
            padding: 0 7px;

            & > div {
                border-bottom: solid 21px $gray2;
            }
        }

        img.play-button {
            margin-top: -250px;
        }

        div.title {
            margin: -80px 0 -5px 0;
            color: white;
            font-family: 'Myriad Pro Condensed', sans-serif;
            font-weight: bold;
            font-size: 56px;
            letter-spacing: 1px;
        }
    }

    div.bildMitTextOverlay {
        position: relative;

        div {
            font-size: 34px;
            font-weight: bold;
            color: white;
            text-align: center;
            position: absolute;
            top: 25px;
            width: 100%;
        }
    }

    div.buttonText {
        color: white;
        font-weight: bold;
        background-color: $gray2;
        padding: 10px 20px 10px 10px;
        width: fit-content;
        margin: 0 auto 20px auto;
        svg {
            vertical-align: -4px;
            width: 30px;
            height: 30px;
            * {
                fill: white;
            }
        }
        a, a:hover, a:visited, a:visited:hover {
            color: white;
            text-decoration: none;
        }
    }

    div.kalenderLink {
        position: relative;
        > div {
            position: absolute;
            bottom: 60px;
            left: 50%;
            transform: translateX(-50%);
            text-shadow: 10px 10px 20px black;
            font-weight: bold;
            font-size: 40px;
            a, a:focus, a:hover, a:visited {
                color: white;
                text-decoration: none;
            }

            img {
                height: 95px;
                margin-left: 60px;
            }
        }
    }

    div.event {
        font-size: 22px;
        font-weight: bold;
        color: $gray4;
        background-color: $gray5;
        padding: 10px 20px;
    }

    @include media-breakpoint-up(md) {
        div.headlines {
            width: 700px;
        }
    }
    @include media-breakpoint-down(md) {
        padding: 0 10px;
        h1 {
            font-size: 54px;
        }
        h2 {
            font-size: 36px;
            padding: 10px;
        }
        p {
            font-size: 24px;
        }

        div.bildMitTextOverlay {
            div {
                position: static;
                color: $gray2;
            }
            br {
                display: none;
            }
        }
        div.videoBox {
            margin-left: 0;
            margin-right: 0;
        }

        div.bigGreenTitle {
            hyphens: auto;
        }

        div.kalenderLink > div {
            bottom: auto;
            font-size: 32px;
            padding-left: 10px;

            img {
                height: 40px;
                margin-left: auto;
            }
        }

        footer {
            margin: 30px -10px 0 -10px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
