div.bigGreenTitle {
    margin: 20px 0;
    background-image: linear-gradient($gray2 0, $gray2 60px, white 60px), linear-gradient(white 0, white calc(100% - 60px), $gray2 calc(100% - 60px));
    background-size: 7px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;

    h1, h2, h3 {
        text-align: center;
        padding: 0 10px;
        color: $lightgreen;
        font-size: 76px;
        font-family: 'DIN Condensed', sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: -30px;
        background-image: linear-gradient($gray2 0, $gray2 100%);
        background-size: 60px 7px;
        background-repeat: no-repeat;
        hyphens: auto;
    }
    div.rightBottomCorner {
        font-size: 10px;
        background-image: linear-gradient($gray2 0, $gray2 100%);
        background-size: 60px 7px;
        background-position: 100% 100%;
        background-repeat: no-repeat;
    }
}
@include media-breakpoint-down(sm) {
    div.bigGreenTitle h1 {
        padding-top: 20px;
        margin-bottom: -10px;
    }
}
body.standard {
    section.container-fluid {
        position: relative;
    }
    h2.subline {
        font-size: 42px;
        line-height: 56px;
        font-weight: bold;
        text-align: center;
    }
    @include media-breakpoint-up(sm) {
        h2.subline {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: MIN(95vw, 800px);
            color: white;
        }
    }
}
