main.ueber-uns {
  div p {
    padding: 0 30px;

    &:first-of-type::after {
      content: " ";
      width: 100px;
      display: block;
      margin: 0 auto 30px;
      padding-bottom: 15px;
      border-bottom: solid 10px $gray2;
    }
  }
  div.leitungsteamTitle {
    text-align: center;
    margin-bottom: 50px;
    background: linear-gradient(to bottom, white, white 40%, $gray2 40%, $gray2 60%, white 60%);

    span {
      background: white;
      padding: 0 10px;
    }
  }

  div.lTeamBox {
    margin-bottom: 30px;

    img {
      width: 375px;
    }

    div.lteamName {
      margin-bottom: 30px;
    }
    div.lteamText {
      line-height: 38px;
    }
  }
}

@include media-breakpoint-down(sm) {
  div.bigGreenTitle, div.vision div.bigGreenTitle {
    margin: 0;

    h2 {
      margin-bottom: -10px;
      padding-top: 20px;
    }
  }
}

div.vision {
  width: 100%;
  margin: 50px 0;
  padding-top: 10px;
  background-color: $pastelGreen;

  div.bigGreenTitle {
    background-image: linear-gradient($gray2 0, $gray2 60px, $pastelGreen 60px), linear-gradient($pastelGreen 0, $pastelGreen calc(100% - 60px), $gray2 calc(100% - 60px));
  }
  h2 {
    color: white;
    font-size: 76px;
    font-family: 'DIN Condensed', sans-serif;
    letter-spacing: 4px;
    margin-bottom: -40px;
  }

  div.visionBody {
    padding: 20px 50px 50px;
    line-height: 3rem;
  }
}

div.arrowItem {
  span {
    background: white;
    padding-right: 15px;
  }
  &::before {
    content: " ";
    height: 30px;
    display: block;
    margin-bottom: -30px;
    border-bottom: solid $gray2 6px;
  }
  &::after {
    content: "►";
    float: right;
    font-size: 35px;
    margin: 1px -10px -6px 0;
  }
}
@include media-breakpoint-down(md) {
  div.arrowItem::before {
    display: none;
  }
}

div.whiteOnGreenTitle {
  margin-top: 80px;
  color: white;
  background: $lightgreen;

  h2 {
    font-size: 76px;
    font-family: 'DIN Condensed', sans-serif;
    letter-spacing: 4px;
  }
  @include media-breakpoint-down(sm) {
    margin-left: -10px;
    margin-right: -10px;
    width: 100vw;
    h2 {
      padding: 0 20px;
    }
  }
}

@include media-breakpoint-only(xs) {
  div.beziehungenImageSmall {
    width: calc(50vw - 26px);
  }
}
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-only($breakpoint) {
    div.beziehungenImageSmall {
      $cmw: $container-max-width / 2 - 5px;
      width: $cmw;
    }
  }
}
div.beziehungenSmall {
  background: $gray2;
}
div.container.beziehungen {
  color: white;
  background: $gray2;
  padding: 30px 50px;
  hyphens: auto;
}
@include media-breakpoint-up(xl) {
  $height: calc((100vw - 1041px) * 15 / 23);
  div.beziehungenImage {
    width: calc((100vw - 1041px) / 2);
    height: $height;
  }
  div.container.beziehungen {
    height: $height;
    overflow: auto;
  }
}
div.beziehungen {
  svg {
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }
    * {
      fill: white;
    }
    &:first-of-type {
      transform: rotate(180deg);
    }
    &:last-of-type {
      transform: rotate(90deg);
    }
  }
  & > div {
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }
  }
}