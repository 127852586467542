main.kids {
  p, ul {
    margin-bottom: 3rem;
  }
}
p.kinderschutzprogramm {
  margin-top: 20px;

  a {
    color: white;
    background-color: $gray2;
    text-decoration: none;
    padding: 11px;
  }

  img.pfeil {
    width: 60px;
    padding-bottom: 5px;
    margin-left: 20px;
  }
  img.logo {
    margin: 10px auto;
    display: block;
    width: 150px;
  }
}