main.spenden {
  h2 {
    text-transform: uppercase;
  }
  p:first-of-type {
    margin-bottom: 3rem;
  }
  input[type="image"] {
    width: 105px;
    display: block;
    margin: 0 auto;
  }
}