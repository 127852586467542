$gray1: #676767; // cmyk(0, 0, 0, 60)
$gray2: #6f7d85; // cmyk(58, 39, 36, 18) wäre eigentlich #588086
$gray3: #406068; // cmyk(64, 46, 42, 30) wäre eigentlich
$gray4: #979fa6; // cmyk(58, 39, 36, 18) 70%
$gray5: #ecedef;
$lightgreen: #c8d419; // cmyk(30, 0, 95, 0) wäre eigentlich #b3ff0d
$pastelGreen: #cede8d;
$primary: $lightgreen;
$secondary: $gray2;

// Breakpoints / Grid-Konfiguration
// Ersetzt in _bootstrap-config.scss die Bootstrap-Standard-Konfiguration (ansprechbar mit $grid-breakpoints)
$grid-breakpoints: (
    'xs': 0,
    'sm': 600px,
    'md': 900px,
    'lg': 1200px,
    'xl': 1500px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1024px
);
$container-padding-x: 0;
$offcanvas-color: white;
$offcanvas-bg-color: black;

@import "~bootstrap/scss/bootstrap";
