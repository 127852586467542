@font-face {
    font-family: 'DIN Condensed';
    font-weight: bold;
    src: url('../fonts/DIN_Condensed_Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Myriad Pro Regular';
    src: url('../fonts/MyriadPro-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Myriad Pro Condensed';
    font-weight: bold;
    src: url('../fonts/MyriadPro-BoldCond.otf') format('opentype');
}
